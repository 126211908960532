<template>
    <header class="topHeader">
        <h1 class="title">
            <el-icon style="vertical-align: -12%;" @click="goBack">
                <ArrowLeft />
            </el-icon>
            {{ props.cityName }}
        </h1>
    </header>
</template>
<script setup>
import { ArrowLeft } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
const props = defineProps({
    cityName: {
        type: String,
        required: true,
    }
});

const router = useRouter();
const goBack = () => router.go(-1);
</script>
<style scoped lang="scss">

.header {
    padding: 20px 16px 20px 16px;
    z-index: 19;
}
.title {
    font-style: Semibold;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    vertical-align: middle;
    z-index: 19;
}

</style>