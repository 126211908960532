<template>
  <ul class="slider">
    <li
      v-for="(item,index) in Store.getters.filteredPicWallImgList"
      :key="item.id"
      :name="index"
      class="inlineBlock"
      @click="select"
    >
      <img
        :src="item.img_url"
        class="cover"
        :class="Store.state.currentIndex == index? 'active': null"
      />
    </li>
  </ul>
</template>
<script setup>
import { useStore } from "vuex";
const Store = useStore();

const select = (e) => {
    let currentIndex = e.currentTarget.getAttribute('name');
    Store.commit('setCurrentIndex', currentIndex)
}

</script>
<style scoped lang="scss">

ul.slider {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  background: linear-gradient(180deg, rgba(8, 13, 19, 0) 5.26%, #0c1117 26.48%);
  border-radius: 4px;
  li.inlineBlock {
    display: inline-block;
    width: 70px;
    height: 70px;
    .cover {
      width: 56px;
      height: 56px;
      margin: 10px;
      border-radius: 2px;
    }
    .cover.active {
      margin: 12px 10px 10px 10px;
      border: 4px solid #02bdcd;
    }
  }
}
</style>