<template>
  <div class="appContainer">
    <Earth></Earth>
    <Header :cityName="Store.state.selectedCity"></Header>
    <CategoryOfCity :selectable="true"></CategoryOfCity>
    <Introduction></Introduction>
    <div class="sliderContainer">
      <Slider></Slider>
    </div>
  </div>
</template>
<script setup>
import Earth from "@/components/NewEarth/index.vue";
import Header from "@/components/app/areaDetail/Header.vue";
import CategoryOfCity from "@/components/app/CategoryOfCity.vue";
import Introduction from "../../components/app/areaDetail/introduction.vue";

import { useStore } from "vuex";
import { onMounted, onUnmounted } from "vue";
import Slider from "../../components/app/areaDetail/Slider.vue";
const Store = useStore();

let OX, OY, OZ;

onMounted(() => {
  Store.commit('setCurrentIndex', 0);
  const { x, y, z } = window.app.camera.position;
  OX = x;
  OY = y;
  OZ = z;
  // console.log(`OX:${OX},OY:${OY},OZ:${OZ}`);
  Store.dispatch('unselectCategory');
  Store.dispatch('unselectCategory');
  window.app.camera.position.y = -4;
  window.app.camera.zoom = 0.3;
  // window.app.camera.position.set(OX, OY, OZ);
  // window.app.camera.lookAt(0, 0, 0);
  window.app.camera.updateProjectionMatrix();
});

onUnmounted(() => {
  window.app.camera.zoom = 1;
  // window.app.camera.position.set(OX, OY, OZ);
  window.app.camera.updateProjectionMatrix();
})

</script>
<style scoped lang="scss">
.sliderContainer {
  position: absolute;
  bottom: 22px;
  left: 16px;
  width: calc(100% - 32px);
  height: 70px;
  z-index: 19;
}
</style>