<!-- 地区的项目简介页 -->
<template>
  <div class="intro">
    <div class="abstractContainer" @click="goToProjectDetail">
      <div class="projectType">
        {{ project.type }}
      </div>
      <el-image :src="project.img_url" alt="project cover image not fount" class="cover"></el-image>
      <h1 class="title">{{ project.title }}</h1>
      <div class="abstractBContainier" >
        <span class="time contentColor">{{ project.time }}</span>
        <span class="author contentColor">{{ project.author }}</span>
        <span class="collectedAt contentColor">收录于 {{ project.collectedAt }}</span>
      </div>
      <div class="excerpt contentColor">{{ project.excerpt }}</div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

const Store = useStore();
const router = useRouter();

const project = computed(() => {
  return Store.getters.currentProject;
});
const goToProjectDetail = (e) => {
  const app = window.app;
  app.needAnimating = false;
  router.push(`/app/detail/${project.value.id}`);
  // Store.commit("stopAnimation");
  e.stopPropagation();
};
// const current
</script>
<style lang="scss" scoped>
.intro {
  width: calc(100% - 32px);
  height: calc(100% - 260px);
  background-color: black;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  box-sizing: content-box;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */
  z-index: 19;
  border-radius: 4px;

}

.abstractContainer {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  overflow-y: scroll;
  div.projectType {
    position: absolute;
    top: 0;
    right: 0;
    width: 51px;
    // height: 28px;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 0px 4px 0px 0px;
    vertical-align: middle;
    text-align: center;
    font-size: 12px;
    line-height: 22px;
    color: #FFFFFF;
  }

  h1.title {
    text-align: center;
  }

  .abstractBContainier {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
  }
}

.cover {
  width: 151px;
  height: 151px;
  margin: 16px auto;
  text-align: center;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.author {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.015em;
}

.time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.015em;
}

.collectedAt {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
  /* identical to box height */

  letter-spacing: 0.015em;
}

.excerpt {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  padding: 16px;
  text-align: justify;
}

.contentColor {
  color: rgba(255, 255, 255, 0.6);
}
</style>